import { Link } from 'react-router-dom';


const Sidebar = () => {
    return (
        
        <div className="sidebar-wrapper sidebar-theme">
            <div id="dismiss" className="d-lg-none"><i className="flaticon-cancel-12" /></div>
            <nav id="sidebar">
                <ul className="navbar-nav theme-brand flex-row  text-center">
                    <li className="nav-item theme-logo">
                        <a href="#">
                            <img src="template_files/white_icon.png" className="navbar-logo" alt="logo" />
                        </a>
                    </li>
                    <li className="nav-item theme-text">
                        <a href="#" className="nav-link">
                            <img src="template_files/logohorn.png" height={80} alt="" />
                        </a>
                    </li>
                </ul>
                <ul className="list-unstyled menu-categories ps" id="accordionExample">
                    <li className="menu">
                        
                        <Link to="/" aria-expanded="false" className="dropdown-toggle">
                            <div className>
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-home">
                                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                                    <polyline points="9 22 9 12 15 12 15 22" />
                                </svg>
                                <span> Home</span>
                            </div>
                        </Link>
                    </li>
                    <li className="menu">
                        <Link to="questions" aria-expanded="false" className="dropdown-toggle">
                            <div className>
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-airplay">
                                    <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1">
                                    </path>
                                    <polygon points="12 15 17 21 7 21 12 15" />
                                </svg>
                                <span> Content</span>
                            </div>
                        </Link>
                        <ul className="collapse submenu list-unstyled" id="submenu" data-parent="#accordionExample">
                            <li>
                                <a href="javascript:void(0);"> Submenu 1 </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);"> Submenu 2 </a>
                            </li>
                        </ul>
                    </li>
 
 
                    <div className="ps__rail-x" style={{ left: '0px', bottom: '0px' }}>
                        <div className="ps__thumb-x" tabIndex={0} style={{ left: '0px', width: '0px' }} />
                    </div>
                    <div className="ps__rail-y" style={{ top: '0px', right: '0px' }}>
                        <div className="ps__thumb-y" tabIndex={0} style={{ top: '0px', height: '0px' }} />
                    </div>
                </ul>
            </nav>
        </div>

    )
}

export default Sidebar;