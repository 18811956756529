import { useEffect, useState } from "react"
import data from "../../../mock/content.json"
import Compiler from "../../../compiler"
data.content = data.content.filter(n=>n.enabled);
const Codevideo = () => {
    const url = window.location.search.replace("?", "") 
    // const d = data.content.filter(n=>n.id==url)[0] ? data.content.filter(n=>n.id==url)[0] : data.content[0]
    window.editor=null;
    window.resultEditor=null;
    window.consoleEditor=null;
    let compiler = new Compiler();

    const [d, setNewData] = useState({})

    const runCode = ()=>{

       let result = compiler.runCode(d.qid,window.editor.getValue(), document.getElementById("testCase").value)
       console.log("result", result)
       window.resultEditor.setValue(result)
    }

    const callEditors = () => {

        window.editor = window.ace.edit("editor");
        window.editor.setTheme("ace/theme/monokai");
        window.editor.session.setMode("ace/mode/javascript");

        window.resultEditor = window.ace.edit("resultEditor");
        window.resultEditor.setTheme("ace/theme/chrome");
        window.resultEditor.session.setMode("ace/mode/javascript");



    }
    const loadData = ()=> fetch("template_files/content.json")
    .then(response=>response.json())
    .then(data=>data.content.filter(n=>n.enabled))
    .then(data=>{console.log("returned data", data); return data;})
    .then(data=>data.filter(n=>n.id==url)[0] ? data.filter(n=>n.id==url)[0] : data[0])
    .then(data=>{setNewData(data);return data;})
    .then((d)=>{
        callEditors();
    })

    useEffect(() => {
        
        loadData();

    }, []);


    return (
        <div id="content" className="main-content">
            <div className="layout-px-spacing">
                <p />
                <div className="row">

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                        <div className="widget widget-content-area br-4 small-height">
                            <div className="widget-one">
                                <button className="btn btn-success  mb-4 btn-rounded btn-sm float-right" onClick={runCode}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-play"><polygon points="5 3 19 12 5 21 5 3" /></svg>
                                    Run Code
                                </button>
                                <div id="editor" dangerouslySetInnerHTML={{ __html: d.initialLog }}>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
                        <div className="widget widget-content-area br-4">
                            <div className="widget-one">
                                <h4>Testcases:</h4>
   
                                 TestCase : <input type="text" id="testCase" defaultValue={d.testCase}  style={{ width: '70%' }} aria-label="Username" /><br /><br />
                               
                             </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
                        <div className="widget widget-content-area br-4 smaller-height">
                            <div className="widget-one ">
                                <h4 className="left d-inline">Results Console</h4>
                                <div id="resultEditor">-&gt;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Codevideo