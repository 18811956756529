const Compiler = function () {

    let expected = "";
    let actual = "";
    let reverseLinkedList = null;

    const convertToLinkedList = (arr) => {
        arr = arr.split(",")
        return arr.reduceRight((next, value) => ({ value, next }), null);
    }

    const convertToArray = (arr) => eval(arr);

    const convertToBST = (arr) => {
        function insertBinTree (t = {val: void 0, left: void 0, right: void 0}, n){
            t.val !== void 0 ? t.val > n ? t.left = insertBinTree(t.left,n)
                                             : t.right = insertBinTree(t.right,n)
                               : t.val = n;
            return t;
          }

        let tree = arr.reduce(insertBinTree, void 0);
        console.log("tree ", tree)
        return tree;
    };

    const Problems = {
        reverseLinkedList: (head) => {
            let prev = null
            let next = null
            let current = head
            while (current) {
                next = current.next
                current.next = prev
                prev = current
                current = next

            }
            return prev
        },
        maxSubArray: function (nums) {
            let currentMax = nums[0]
            let max = nums[0]

            for (let i = 1; i < nums.length; i++) {
                let num = nums[i]
                currentMax = Math.max(num, currentMax + num)
                max = Math.max(currentMax, max)
            }
            return max

        },
        isValidBST: function (root) {
            let arr = []

            function inOrder(node) {
                if (!node) return
                inOrder(node.left)
                arr.push(node.val)
                inOrder(node.right)
            }

            inOrder(root)
            for (let i = 0; i < arr.length - 1; i++) {
                if (arr[i] >= arr[i + 1]) return false
            }
            return true
        },
        numIslands: function(grid){
            let num = 0
            
            function dfs(i,j){
                
                grid[i][j] = "0"
                
                if(i>0 && grid[i-1][j]=="1") dfs(i-1,j);
                if(i < grid.length-1 && grid[i+1][j]=="1") dfs(i+1,j);
                if(j>0 && grid[i][j-1]=="1") dfs(i, j-1);
                if(j < grid[i].length-1 && grid[i][j+1]=="1") dfs(i, j+1);
                
                
            }
            
            for(let i =0; i < grid.length; i++){
                for(let j =0; j < grid[i].length; j++){
                    if(grid[i][j]=="1"){
                        num++
                        dfs(i,j)
                    }
                }
            }
        
            return num
        }
    }

    this.runCode = (question, submission, testCases) => {
        let testCaseExpected = testCases;
        let testCaseActual = testCases;
        if (question.includes("LinkedList")) {
            testCaseExpected = convertToLinkedList(testCases)
            testCaseActual = convertToLinkedList(testCases)
        }
        else if (question.includes("BST")) {
            testCaseExpected = convertToArray(testCases)
            testCaseActual = convertToArray(testCases)
            console.log(testCaseActual,testCaseExpected)
            testCaseExpected = convertToBST(testCaseExpected)
            testCaseActual = convertToBST(testCaseActual)
        }
        else {
            testCaseExpected = convertToArray(testCases)
            testCaseActual = convertToArray(testCases)
        }
        expected = Problems[question](testCaseExpected);

        //clean previous script
        var node = document.getElementById("addedScript");
        if (node && node.parentNode) {
            node.parentNode.removeChild(node);
        }
        window[question] = null;
        //End Clean Previous

        //Add new script
        var script = document.createElement('script');
        script.id = "addedScript";
        try {
            script.appendChild(document.createTextNode(submission));
            document.body.appendChild(script);
        } catch (e) {
            script.text = submission;
            document.body.appendChild(script);
        }
        //End add new script

        //Execute script
        try {
            actual = window[question](testCaseActual)
        } catch (e) {
            return "ERROR: Your code has an error or the " + question + " function can not be found \r\n " + e;
        }
        //End execute script
        if (typeof expected === 'object') {
            actual = JSON.stringify(actual);
            expected = JSON.stringify(expected)
        }
        if (actual !== expected) {
            return "FAILED \r\n \r\nExpected: " + JSON.stringify(expected) + "  \r\n \r\nGot: " + JSON.stringify(actual)
        } else {
            return "SUCCESS \r\n \r\nExpected: " + JSON.stringify(expected) + "  \r\n \r\nGot: " + JSON.stringify(actual)
        }

    }

}

export default Compiler;