import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//import data from "../../../mock/content.json"
//data.content = data.content.filter(n=>n.enabled);

const Questions = () => {
    const [filterString, setFilterString] = useState("")
    const [sortBy, setSortBy] = useState("newest")    
    const [newData, setNewData] = useState({content:[]})
    const [filterData, setFilterData] = useState({content:[]})

    const loadData = ()=> fetch("template_files/content.json")
    .then(response=>response.json())
    .then(data=>data.content.filter(n=>n.enabled))
    .then(data=>{console.log("returned data", data); return data;})
    .then(data=>{setNewData({content:data}); setFilterData({content:data});})
    
    useEffect(()=>{
        loadData();
    },[])

    useEffect(()=>{
        const sortData = {...filterData}
        switch(sortBy){
            case "Newest":
                console.log(sortBy)
                sortData.content.sort((a,b)=>b.id -a.id)
                setNewData(sortData)
                break;
            case "Oldest" :
                console.log(sortBy)
                sortData.content.sort((a,b)=>a.id -b.id)
                setNewData(sortData)
                break;
            case "Category":
                console.log(sortBy)
                sortData.content.sort((a,b)=>a.category.localeCompare(b.category))
                setNewData(sortData)
                break;
            case "Title" :
                console.log(sortBy)
                sortData.content.sort((a,b)=>a.title.localeCompare(b.title))
                setNewData(sortData)
                break;
            }
    }, [sortBy])


    useEffect(()=>{
       const filterData = newData.content.filter(n=>(n.title.toLocaleLowerCase()+" "+n.category.toLocaleLowerCase()).includes(filterString.toLocaleLowerCase()))
    
       setFilterData({content:filterData})

    }, [filterString])

    return (
        <div id="content" className="main-content">
            <div className="layout-px-spacing">
                <div className="page-header">
                    <div className="page-title">
                        <h2 className="redBackground">Questions and Video Explanations</h2>
                    </div>
                </div>
                {/* CONTENT AREA */}
                <div className="row">
                    <div className="col-10">
                        <div className="search-input-group-style input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-search">
                                    <circle cx={11} cy={11} r={8} />
                                    <line x1={21} y1={21} x2="16.65" y2="16.65" />
                                </svg></span>
                            </div>
                            <input type="text" className="form-control" placeholder="Let's find your question in fast way" aria-label="Username" aria-describedby="basic-addon1" onChange={(e)=>setFilterString(e.target.value)}/>
                        </div>
                    </div>
                    <div className="col-2">
                        <select className="selectpicker btn btn-outline-success" onChange={(e)=>setSortBy(e.target.value)}>
                            <option selected>Order by</option>
                            <option value={"Category"}>Category</option>
                            <option value={"Title"}>Title</option>
                            <option value={"Newest"}>Newest</option>
                            <option value={"Oldest"}>Oldest</option>
                        </select>
                    </div>
                </div>
                <ul className="list-group ">
                   {filterData.content && filterData.content.map((n,i)=>(
                                           <button key={i} className="list-group-item btn btn-success text-left" >
                                           <Link to={"/code?"+n.id}>
                                               <div style={{width:"100%"}}>
                                               <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
                                                   <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                               </svg>
                                               <b>Problem: </b> {n.title} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Category: </b>{n.category}
                                               </div>
                                           </Link>
                                           </button>
                   ))}
                    <h3>More questions will be added soon!</h3>
                  
                   
                </ul>
                {/* CONTENT AREA */}
            </div>
        </div>
    )
}

export default Questions;