import { BrowserRouter } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import Content from "../content/Content";

const Main = () => {
    return (
        <BrowserRouter>
        <div className="main-container sidebar-closed sbar-open" id="container">
            <div className="overlay show" />
            <div className="cs-overlay" />
            <div className="search-overlay" />
            <Sidebar/>
            <Content/>  
        </div>
        </BrowserRouter>
    )
}

export default Main;