import { useEffect, useState } from "react"
import data from "../../../mock/content.json"
import Compiler from "../../../compiler"
data.content = data.content.filter(n=>n.enabled);
const Code = () => {
    const url = window.location.search.replace("?", "") 
    // const d = data.content.filter(n=>n.id==url)[0] ? data.content.filter(n=>n.id==url)[0] : data.content[0]
    window.editor=null;
    window.resultEditor=null;
    window.consoleEditor=null;
    let compiler = new Compiler();

    const [d, setNewData] = useState({})

    const runCode = ()=>{

       let result = compiler.runCode(d.qid,window.editor.getValue(), document.getElementById("testCase").value)
       console.log("result", result)
       window.resultEditor.setValue(result)
    }

    const callEditors = () => {

        window.editor = window.ace.edit("editor");
        window.editor.setTheme("ace/theme/monokai");
        window.editor.session.setMode("ace/mode/javascript");

        window.resultEditor = window.ace.edit("resultEditor");
        window.resultEditor.setTheme("ace/theme/chrome");
        window.resultEditor.session.setMode("ace/mode/javascript");

        window.consoleEditor = window.ace.edit("consoleEditor");
        window.consoleEditor.setTheme("ace/theme/monokai");
        window.consoleEditor.session.setMode("ace/mode/javascript");

    }
    const loadData = ()=> fetch("template_files/content.json")
    .then(response=>response.json())
    .then(data=>data.content.filter(n=>n.enabled))
    .then(data=>{console.log("returned data", data); return data;})
    .then(data=>data.filter(n=>n.id==url)[0] ? data.filter(n=>n.id==url)[0] : data[0])
    .then(data=>{setNewData(data);return data;})
    .then((d)=>{
        callEditors();
    })

    useEffect(() => {
        
        loadData();

    }, []);


    return (
        <div id="content" className="main-content">
            <div className="layout-px-spacing">
                <p />
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
                        <div className="widget widget-content-area br-4 good-height">
                            <div className="widget-one">
                                <ul className="nav nav-tabs  mb-3 mt-3" id="simpletab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" /><polyline points="14 2 14 8 20 8" /><line x1={16} y1={13} x2={8} y2={13} /><line x1={16} y1={17} x2={8} y2={17} /><polyline points="10 9 9 9 8 9" /></svg>
                                            &nbsp;Question&nbsp;
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-youtube"><path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z" /><polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02" /></svg>
                                            &nbsp;Video Explanation&nbsp;
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="solution-tab" data-toggle="tab" href="#solution" role="tab" aria-controls="solution" aria-selected="false">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-code"><polyline points="16 18 22 12 16 6" /><polyline points="8 6 2 12 8 18" /></svg>
                                            &nbsp;Solution&nbsp;
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="simpletabContent">
                                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <h3>{d.title}</h3>
                                        <p className="mb-4">
                                        <div  dangerouslySetInnerHTML={{ __html: d.question }}>
                                        </div>
                                        </p>

                                    </div>
                                    <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                        <p className>
                                            <iframe width="100%" height={515} src={d.video}>
                                            </iframe>
                                        </p>
                                    </div>
                                    <div className="tab-pane fade" id="solution" role="tabpanel" aria-labelledby="solution-tab">
                                        <div id="consoleEditor"  dangerouslySetInnerHTML={{ __html: d.solution }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
                        <div className="widget widget-content-area br-4 good-height">
                            <div className="widget-one">
                                <button className="btn btn-success  mb-4 btn-rounded btn-sm float-right" onClick={runCode}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-play"><polygon points="5 3 19 12 5 21 5 3" /></svg>
                                    Run Code
                                </button>
                                <div id="editor" dangerouslySetInnerHTML={{ __html: d.initialLog }}>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
                        <div className="widget widget-content-area br-4">
                            <div className="widget-one">
                                <h4>Testcases:</h4>
   
                                 TestCase : <input type="text" id="testCase" defaultValue={d.testCase}  style={{ width: '70%' }} aria-label="Username" /><br /><br />
                               
                             </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
                        <div className="widget widget-content-area br-4 medium-height">
                            <div className="widget-one ">
                                <h4 className="left d-inline">Results Console</h4>
                                <div id="resultEditor">-&gt;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Code