import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

import Home from "./home/Home";
import Questions from "./questions/Questions";
import Code from "./code/Code";
import Codevideo from "./codevideo/Codevideo";

const Content = () => {
    return (
   
            <Routes>
                <Route path="/questions" element={<Questions/>}> 
                </Route>
                <Route path="/" element={<Home/>}>
                </Route>
                <Route path="/code" element={<Code/>}>
                </Route>
                <Route path="/codevideo" element={<Codevideo/>}>
                </Route>
            </Routes>

    )
}

export default Content;