import { Link } from "react-router-dom";

const Home = () => {
    return (
        <div id="content" className="main-content">
            <div className="layout-px-spacing">
                <div className="page-header">
                    <div className="page-title">
                        <h1 className="redBackground">Welcome to my website, my name is Horncode, I am your coding little devil</h1>
                        <br /><br />
                        <h3 className="redBackground">Here you will find my VIDEO EXPLANATIONS to common algorithm coding interview questions</h3>
                        <br /><br />
                        <Link to="/questions">
                            <button className="btn btn-success ">Click here to access my content now</button>
                        </Link>
                        <br /><br /><br /><br />
                        <a href="https://www.youtube.com/channel/UCby1EZfQ1-i_XVa4z7-fj4A" target="_blank">
                        Check my Youtube channel &nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-youtube"><path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path><polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon></svg>
                        </a>
                        <br /><br />
                        <a href="https://twitter.com/horncode" target="_blank">
                            You can also find me on Twitter &nbsp;
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>
                        </a>
                    </div>
                </div>
                {/* CONTENT AREA */}
                {/* CONTENT AREA */}
            </div>
        </div>
    )
}

export default Home;