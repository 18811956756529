const Navbar = () => {
    return (
        <div className="header-container fixed-top">
            <header className="header navbar navbar-expand-sm expand-header">
                <a href="javascript:void(0);" className="sidebarCollapse" data-placement="bottom"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu">
                    <line x1={3} y1={12} x2={21} y2={12} />
                    <line x1={3} y1={6} x2={21} y2={6} />
                    <line x1={3} y1={18} x2={21} y2={18} />
                </svg></a>
                <ul className="navbar-item flex-row ml-auto">
                    <li className="nav-item dropdown user-profile-dropdown  order-lg-0 order-1">
                        <a href="javascript:void(0);" className="nav-link dropdown-toggle user" id="userProfileDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user">
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                <circle cx={12} cy={7} r={4} />
                            </svg>
                        </a>
                        <div className="dropdown-menu position-absolute" aria-labelledby="userProfileDropdown">
                            <div className="user-profile-section">
                                <div className="media mx-auto">
                                    <img src="https://pbs.twimg.com/profile_images/1443356694226948098/6vE8Vdnf_400x400.jpg" className="img-fluid mr-2" alt="avatar" />
                                    <div className="media-body">
                                        <h5>HornCode</h5>
                                        <p>Devil</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </li>
                </ul>
            </header>
        </div>

    )
}

export default Navbar;